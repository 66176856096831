import { useState } from 'react';

const useForm = (initialValues) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const validate = (name, value) => {
        let error = '';
        if ((name === 'email' || name === 'broker') && !value) {
            error = `${name} required`;
        } else if (name === 'email' && value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            error = 'Invalid email';
        }
        return error;
    };

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: validate(name, value)
        });
    };

    return { values, errors, setErrors, onChangeInput, validate };
};

export default useForm;
