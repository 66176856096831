import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Landing from "./scenes/landing"
import SignUp from "./scenes/signup";
import SignIn from "./scenes/signin";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";

function App() {
	const [theme, colorMode] = useMode();
	const [isSidebar, setIsSidebar] = useState(true);
	const location = useLocation();

	const shouldDisplaySidebarAndTopbar = location.pathname !== '/signup' && location.pathname !== '/signin' && location.pathname !== '/';

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className="app">
					{shouldDisplaySidebarAndTopbar && <Sidebar isSidebar={isSidebar} />}
					<main className="content">
						{shouldDisplaySidebarAndTopbar && <Topbar setIsSidebar={setIsSidebar} />}
						<Routes>
							<Route path="/" element={<Landing />} />
							<Route path="/signup" element={<SignUp />} />
							<Route path="/signin" element={<SignIn />} />
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path="/user-profile" element={<Form />} />
							<Route path="/connect-broker" element={<Invoices />} />
							<Route path="/leaderboard" element={<Team />} />
							<Route path="/trade-history" element={<Contacts />} />
							<Route path="/bar" element={<Bar />} />
							<Route path="/pie" element={<Pie />} />
							<Route path="/line" element={<Line />} />
							<Route path="/faq" element={<FAQ />} />
							<Route path="/calendar" element={<Calendar />} />
							<Route path="/geography" element={<Geography />} />
						</Routes>
					</main>
				</div>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;