import { ReactComponent as Star } from 'assets/icons/star.svg';
import Button from 'components/Basic/Button';
import BasicInput from 'components/Input/BasicInput';
import useForm from 'hooks/useForm';
import styled from 'styled-components';
import { device } from 'styles/BreakPoints';
import { Box } from 'styles/Global';

import axios from 'axios';

const FormStyle = styled.form``;

const TextStyle = styled.span`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    svg {
        margin-right: 16px;
    }
    @media ${device.sm} {
        display: none;
    }
`;

const ErrorText = styled.span`
    color: red;
    font-size: 14px;
    align-self: flex-start;
`;

const Form = ({ handleAddedInList }) => {
    const { values, errors, setErrors, onChangeInput, validate } = useForm({ name: '', email: '', broker: '' });

    const submitForm = async (e) => {
        e.preventDefault();

        const formErrors = Object.keys(values).reduce((acc, key) => {
            const error = validate(key, values[key]);
            if (error) acc[key] = error;
            return acc;
        }, {});

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            const response = await signUserUp(values.name, values.email, values.broker);
            console.log(response);
            handleAddedInList();
        } catch (error) {
            console.error(error);
        }
    };

    const signUserUp = async (name, email, broker) => {
        const baseUrl = process.env.REACT_APP_CORE_URL;
        if (!baseUrl) {
            throw new Error('Base URL is not found in environment');
        }

        try {
            const response = await axios.post(`${baseUrl}/waitlist_users`, {
                name: name,
                email: email,
                broker: broker,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in signUserUp:', error);
            throw new Error('Signup failed');
        }
    };

    return (
        <FormStyle onSubmit={submitForm}>
            <BasicInput
                label="Name"
                name="name"
                value={values.name}
                onChange={onChangeInput}
                type="text"
                placeholder="Your name"
            />
            {errors.name && <ErrorText>{errors.name}</ErrorText>}
            <BasicInput
                label="Email"
                name="email"
                value={values.email}
                onChange={onChangeInput}
                type="email"
                placeholder="Your email"
                required
            />
            {errors.email && <ErrorText>{errors.email}</ErrorText>}
            <BasicInput
                label="Broker"
                name="broker"
                value={values.broker}
                onChange={onChangeInput}
                type="text"
                placeholder="Your broker"
                required
            />
            {errors.broker && <ErrorText>{errors.broker}</ErrorText>}
            <Box mt={50} flxRight smNone>
                <TextStyle>
                    <Star />
                    Required field
                </TextStyle>
            </Box>
            <Box mt={40} flxRight >
                <Button title="Join the waitlist" />
            </Box>
        </FormStyle>
    );
};

export default Form;
