export const navbar = [
  {
    title: 'About',
    link: 'https://docs.immutabletrackrecord.com/docs/category/about',
  },
  {
    title: 'Early Adopter Rewards',
    link: 'https://docs.immutabletrackrecord.com/docs/faqs/early-adopter-rewards',
  },
  {
    title: 'Blog',
    link: 'https://docs.immutabletrackrecord.com/blog',
  },
  {
    title: 'Pricing',
    link: 'https://docs.immutabletrackrecord.com/docs/about/what-does-it-cost',
  },
]
