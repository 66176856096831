import styled from 'styled-components'
import { device } from 'styles/BreakPoints'

const ButtonStyle = styled.button`
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 29px;
  padding: 16px 30px;
  font-size: 20px;
  font-weight: 700;

  &:active {
    background-color: ${({ theme }) => theme.colors.tertiaryDark}; /* darken on click */
    box-shadow: inset 4px 4px 7px rgba(0, 0, 0, 0.2); /* create a slight inset shadow */
  }

  @media ${device.md} {
    padding: 11px;
    font-size: 16px;
  }
`

function Button({ title }) {
  return <ButtonStyle>{title.toUpperCase()}</ButtonStyle>
}

export default Button
