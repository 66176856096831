export const LandingTheme = {
  colors: {
    primary: '#2B2B2B',

    secondary: '#140d02', // lhs of split screen
    background: 'black', // rhs of split screen

    text: '#d0d0d6',
    primaryTextColor: '#FFFFFF',
    secondaryTextColor: '#CBCBCB',
    inputPlaceholder: '#C7C7C7',
    blackText: '#000000',
    tertiary: '#ff8',
  },
  fonts: {
    primary: 'Poppins',
  },
  paddings: {
    container: '30px',
  },
}
