import { navbar } from 'config/navbar';
import styled from 'styled-components';
import { device } from 'styles/BreakPoints';
import { SmallDevicesHidden } from 'styles/Global';

const NavbarStyle = styled.nav`
  ul {
    display: flex;
    justify-content: space-between;
    li {
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primaryTextColor};
      cursor: pointer;
      @media ${device.md} {
        font-size: 14px;
        font-weight: 500;
      }
      a {
        text-decoration: none; /* Remove underline */
        color: inherit; /* Inherit color from parent li */
      }
    }
  }

  ${SmallDevicesHidden}
`;

function Navbar() {
  return (
    <NavbarStyle>
      <ul>
        {navbar.map((item, index) => (
          <li key={index}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </NavbarStyle>
  );
}

export default Navbar;
