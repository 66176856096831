import React from 'react';
import styled from 'styled-components';

// import brokerSVG from '../assets/images/broker.svg';
// import tradestationSVG from '../assets/images/tradestation.svg';
// import ethereumSVG from '../assets/images/ethereum.svg';

const FeatureList = [
  {
    title: '1 | Create an account',
    // Svg: brokerSVG,
    description: (
      <>
        Create an ITR account
      </>
    ),
  },
  {
    title: '2 | Connect your broker',
    // Svg: tradestationSVG,
    description: (
      <>
        Connect your usual broker
      </>
    ),
  },
  {
    title: '3 | Trade as usual',
    // Svg: ethereumSVG,
    description: (
      <>
        Your trades are automagically
        <br />
        written to the blockchain
      </>
    ),
  },
  {
    title: '4 | Get funded',
    // Svg: ethereumSVG,
    description: (
      <>
        Show your followers and
        <br />
        potential investors you're legit
      </>
    ),
  },
];

const StepsSection = styled.section`
  background-color: #000080;
  padding: 60px 0;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1500px;
  margin: 0 auto;
`;

const StepBox = styled.div`
  text-align: center;
  width: 400px;
`;

const StepTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 20px;
`;

const StepSubtitle = styled.p`
  font-size: 18px;
  color: #d0d0d6;
  margin-top: 10px;
`;

const SvgContainer = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const HowItWorks = () => {
  return (
    <StepsSection>
      <StepsContainer>
        {FeatureList.map((feature, index) => (
          <StepBox key={index}>
            {/* <SvgContainer>
              <feature.Svg />
            </SvgContainer> */}
            <StepTitle>{feature.title}</StepTitle>
            <StepSubtitle>{feature.description}</StepSubtitle>
          </StepBox>
        ))}
      </StepsContainer>
    </StepsSection>
  );
};

export default HowItWorks;
