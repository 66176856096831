import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'styles/Global'
import { LandingTheme } from 'styles/themes/Basic/LandingTheme'

import HomeWrapper from 'components/Basic/HomeWrapper'
import FAQs from 'components/FAQs/FAQs'
import Footer from 'components/Footer'
import HowItWorks from 'components/HowItWorks'
import GetInContact from 'components/GetInContact'

const Landing = () => (
  <ThemeProvider theme={LandingTheme}>
    <GlobalStyles />
    <HomeWrapper />
    <HowItWorks />
    <FAQs />
    {/* <GetInContact /> */}
    <Footer />
  </ThemeProvider>
)

export default Landing