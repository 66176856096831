


import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from 'styles/Global';

const FaqStyle = styled.footer`
  margin-top: 80px;
`;

const FaqContainer = styled.section`
  background: linear-gradient(to bottom,  #150d02, #03010d);
  display: flex;
  flex: 1;
  border-radius: 20px;
  justify-content: space-around;
  padding: 20px; /* Add padding for better separation */
  padding-bottom: 30px; /* Add padding at the bottom */ 
  margin-bottom: 20px;
`;

const Section = styled.div`
  ul {
    li {
      font-size: 20px;
      margin-top: 40px;
      color: ${({ theme }) => theme.colors.secondaryTextColor};
    }
  }
`;

const SectionTitleContainer = styled.div`
  background-color: #340d02;
  justify-content: space-around;
  margin-bottom: 40px;
  padding: 20px; 
  border-radius: 20px; 
  padding-bottom: 13px; 
`;

const SectionTitle = styled.h5`
  color: #d0d0d6;
  font-size: 70px;
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center; /* Center-align the text content */
  width: 100%; /* Ensure the title takes full width */
  margin-left: auto; /* Align to the center by pushing to the right */
  margin-right: auto; /* Align to the center by pushing to the left */
`;

const FaqText = styled.p`
  padding-left: 15px;
  font-size: 25px;
  font-weight: 450;
  margin-top: 30px;
  cursor: pointer;
  color: #d0d0d6;
`;

const Answer = styled.div`
  color: #d0d0d6;
  font-size: 15px;
  margin-top: 10px;
  padding-left: 54px;
  padding-bottom: 20px; /* Add padding at the bottom */
  max-height: ${({ isExpanded }) => (isExpanded ? '300px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding-bottom 0.5s ease-in-out; /* Include padding-bottom in the transition */

  /* Ensure content is completely hidden when collapsed */
  ${({ isExpanded }) => !isExpanded && `
    padding-bottom: 0;
  `}
`;

function FAQs() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFAQ = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const faqs = [
    {
      question: '🤔 What is an immutable track record?',
      answer: 'A track record that is written to the blockchain, meaning it cannot be tampered with & can be independently verified by anyone.'
    },
    {
      question: '🤷‍♂️ Why do I need this?',
      answer: "Having verified performance is a huge benefit to traders and investors. It's a way to prove your skill to future employers and/or investors while building trust with your followers, not to mention putting 5000 miles between you and the nearest fake trading guru."
    },
    {
      question: '⚙️ How does it work?',
      answer: "Create an account, connect your broker, your trades are written to chain automagically in real time 🔮"
    },
    {
      question: '🔐 Can I make it private?',
      answer: 'Sure, your track record will only be visible once you make it public.'
    },
    {
      question: '👀 Do you have access to my account?',
      answer: 'No. We use Plaid and other 3rd part accoutn aggregators to securely connect to your brokerage account, we never see your api keys or login details.'
    },
    {
      question: '💲 How much does it cost?',
      answer: '$10/month. This also covers the crypto it costs to write your trades to the blockchain.'
    },
    {
      question: '🫰🏼 Are there early bird discounts?',
      answer: "Definitely! The first 50 people who join the waitlist above and post to twitter about it mentioning @ImmutableTR, will get a subscription free of charge for a full 6 months from launch."
    },
    {
      question: '🗠 How do you calculate my performance?',
      answer: "Anyone can see & calculate your performance, it's on the blockchain. But if you really want to know, we'd say go to any block explorer website and download the transactions onto a spreadsheet and go from there."
    },
    {
      question: '💱 Which brokerages do you support?',
      answer: "We integrate with plaid and other 3rd party account aggregators, so any brokerage that they support, we support. This list is substantial. If you can't find your broker then make a noise on our discord or X and we'll add it to the roadmap."
    },
    {
      question: "💎 What's the difference between us and other platforms?",
      answer: "There are no other platforms that create immutable track records - this is a world first. We are not a trading journal, our only job is to log your trades to the blockchain in the background to create an immutable, tamper-proof record of your results. Our focus is to do this in realtime and super reliably with zero downtime and zero missed trades, to advance your goals of getting funded or setting yourself apart from other trading influencers."
    },
    {
      question: '🔗 What is our mission?',
      answer: "To bring transparency to trading. No more fake investment gurus ☢️🙅🏻☠️🤮"
    },
    {
      question: "🧻 Can I use a demo account?",
      answer: "For sure! An immutable track record is an amazing addition to a demo account."
    },
    {
      question: "🚀 Let's get started!",
      answer: "You can secure your place in the waitlist + get 6 months free by signing up above and we'll notify you as soon as we're live!"
    }
  ];

  return (
    <FaqStyle>
      <Container medium>
        <SectionTitleContainer>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </SectionTitleContainer>
        <FaqContainer>
          <Section>

            {faqs.map((faq, index) => (
              <div key={index}>
                <FaqText onClick={() => toggleFAQ(index)}>
                  {faq.question}
                </FaqText>
                <Answer isExpanded={expandedIndex === index}>
                  {faq.answer}
                </Answer>
              </div>
            ))}
          </Section>
        </FaqContainer>
      </Container>
    </FaqStyle>
  );
}

export default FAQs;
