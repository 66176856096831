export const footer = {
  sectionOne: {
    logo: 'Hello',
    subtitle:
      'Log your trades to the blockchain to create an indisputable record of your trading history.',
    copyRights: 'Copyright © 2024 ImmutableTR',
  },
  sectionTwo: {
    title: 'Company',
    company: [
      {
        name: 'Demo accounts',
        link: 'https://docs.immutabletrackrecord.com/docs/about/can-I-use-a-paper-account',
      },
      {
        name: 'Dashboard',
        tag: 'Coming soon!',
        // link: '/',
      },
      {
        name: 'Discord',
        link: 'https://discord.gg/ymFKba7Nbf',
      },
    ],
  },
  sectionThree: {
    title: 'Resources',
    resources: [
      {
        name: 'Blog',
        link: 'https://docs.immutabletrackrecord.com/blog',
      },
      {
        name: 'Learn more',
        link: 'https://docs.immutabletrackrecord.com',
      },
    ],
  },
}
