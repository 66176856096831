import styled from 'styled-components';
// import { ReactComponent as Mail } from 'assets/icons/mail.svg';
// import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as TwitterX } from 'assets/icons/twitterx.svg';
import { ReactComponent as Discord } from 'assets/icons/discord.svg';

const ContactStyle = styled.div`
  margin-top: 11px;
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondaryTextColor};
    margin-left: 15px;
    cursor: pointer; /* Add pointer cursor to indicate clickable */
  }

  svg {
    width: 21px;
    height: 21px;
  }
`;

function Contact() {
	const discordUrl = 'https://discord.gg/ymFKba7Nbf';
	const twitterUrl = 'https://x.com/ImmutableTR';

	const handleDiscordClick = () => {
		window.open(discordUrl, '_blank'); // Open Discord link in a new tab
	};

	const handleTwitterClick = () => {
		window.open(twitterUrl, '_blank'); // Open Twitter link in a new tab
	};

	return (
		<>
			<ContactStyle>
				<TwitterX onClick={handleTwitterClick} />
				<strong><span onClick={handleTwitterClick}>Twitter</span></strong>
			</ContactStyle>
			<ContactStyle>
				<Discord onClick={handleDiscordClick} />
				<strong><span onClick={handleDiscordClick}>Discord</span></strong>
			</ContactStyle>
			{/* <ContactStyle>
        <Mail />
        <span>chris@immutabletrackrecord.com</span>
      </ContactStyle> */}
			{/* <ContactStyle>
        <Phone />
        <span>+1 267-618-1593</span>
      </ContactStyle> */}
		</>
	);
}

export default Contact;


