import React from 'react';
import styled from 'styled-components';
import { Box, Button, TextField, Breadcrumbs, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

const SignUpFormWrapper = styled(Box)`
  margin: 0 auto; /* Centering the form horizontally */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to viewport height for full-page centering */
`;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("invalid email").required("Required"),
  twitterHandle: yup
    .string()
    .required("Required")
    .matches(/^@.+$/, "Twitter handle must start with @"),
  headline: yup.string().required("Required"),
  bio: yup.string().required("Required"),
});


const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  twitterHandle: "",
  headline: "",
  bio: "",
};

const SignUp = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  return (
    <SignUpFormWrapper>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '20px' }}>
        <Typography variant="body1">1. Create Account</Typography>
        <Typography variant="body1">2. Connect Broker</Typography>
        <Typography variant="body1" color="text.secondary">3. Begin your verified track record</Typography>
      </Breadcrumbs>

      <Header
        title="CREATE AN ACCOUNT"
        subtitle="Create an account to start"
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Twitter Handle"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.twitterHandle}
                name="twitterHandle"
                error={!!touched.twitterHandle && !!errors.twitterHandle}
                helperText={touched.twitterHandle && errors.twitterHandle}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Headline"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.headline}
                name="headline"
                error={!!touched.headline && !!errors.headline}
                helperText={touched.headline && errors.headline}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Bio"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bio}
                name="bio"
                error={!!touched.bio && !!errors.bio}
                helperText={touched.bio && errors.bio}
                sx={{ gridColumn: "span 4" }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create Account
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </SignUpFormWrapper>
  );
};

export default SignUp;
