import React from 'react';
import styled from 'styled-components';

// Import SVG icons (placeholders)

import { ReactComponent as twitterSVG } from 'assets/icons/twitterx.svg';
import { ReactComponent as discordSVG } from 'assets/icons/discord.svg';

const FeatureList = [
  {
    title: 'Discord',
    Svg: discordSVG, // Placeholder, replace with actual SVG import
    description: (
      <>
        Join our Discord community to stay updated.
      </>
    ),
  },
  {
    title: 'Twitter',
    Svg: twitterSVG, // Placeholder, replace with actual SVG import
    description: (
      <>
        Follow us on Twitter for latest news and updates.
      </>
    ),
  },
];

const StepsSection = styled.section`
  background-color: #000000;
  padding: 600px 0;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
`;

const StepBox = styled.div`
  text-align: center;
  width: 400px;
`;

const StepTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 20px;
`;

const StepSubtitle = styled.p`
  font-size: 18px;
  color: #d0d0d6;
  margin-top: 10px;
`;

const SvgContainer = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const GetInContact = () => {
  return (
    <StepsSection>
      <StepsContainer>
        {FeatureList.map((feature, index) => (
          <StepBox key={index}>
            <SvgContainer>
              <img src={feature.Svg} alt={feature.title} style={{ width: '100%', height: '100%' }} />
              {/* Use <img> tag with src attribute for SVG */}
            </SvgContainer>
            <StepTitle>{feature.title}</StepTitle>
            <StepSubtitle>{feature.description}</StepSubtitle>
          </StepBox>
        ))}
      </StepsContainer>
    </StepsSection>
  );
};

export default GetInContact;
