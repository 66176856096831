import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Where do I start?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Start by creating your account, then connecting your broker, then
            you need to connect your metamask wallet (create one if you don't have one)
            then you should be able to start logging trades.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How do I create a Metamask account and wallet?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Check out this youtube guide on how to create a metamask wallet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Why do I need to create and fund a metamask wallet?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You need to have a metamask wallet in order to interact with the
            blockchain and log trades.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What does it mean to connect my broker?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Connecting your broker allows us to verify your trades and provide
            you with an immutable track record.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How much does all this cost?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In order to use Immutable Track Record you need a monthly subscription of $10.
            You also need to make sure that your metamask wallet is funded with enough
            currency to pay for the gas fees it costs to log trades.
            We are using the Polygon network to keep gas fees low.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
